import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

import About from "../components/landing/About";
import Samples from "../components/landing/Samples";
import Process from "../components/landing/Process";
import AISpeaker from "components/landing/AISpeaker";
import Advertisement from "components/landing/Advertisement";
import Guide from "components/landing/Guide";

function Landing() {
	return (
		<>
			<About />
			{/* <Samples />
			<Guide />
			<Process />
			<AISpeaker />
			<Advertisement /> */}
		</>
	);
}

export default Landing;
