import { authService, analyticsService } from "fbase";
import { useState } from "react";
import { Link } from "react-router-dom";

const AuthForm4Clova = ({
	state,
	scope,
	redirect_uri,
	client_id,
	response_type,
}) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [newAccount, setNewAccount] = useState(false);
	const [error, setError] = useState("");

	const onChange = (event) => {
		const {
			target: { name, value },
		} = event;
		if (name === "email") {
			setEmail(value);
		} else if (name === "password") {
			setPassword(value);
		}
	};

	const renderRedirect = (link) => {
		return <Link to={link} />;
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		analyticsService.logEvent("clove_계정연동");

		// check redirect url : 보안상 clova.ai 에서 요청한 것인지 확인해야함
		let hname;

		if (redirect_uri.indexOf("//") > -1) {
			hname = redirect_uri.split("/")[2];
		}

		hname = hname.substr(hname.length - 9);
		if (hname !== ".clova.ai") {
			setError("wrong redirect uri");
		} else {
			try {
				let data;
				if (newAccount) {
					data = await authService.createUserWithEmailAndPassword(
						email,
						password
					);
				} else {
					data = await authService.signInWithEmailAndPassword(email, password);
				}
				console.log(data);
				let res_redirect_uri = `${redirect_uri}?state=${state}&code=${data.user.uid}`;
				// window.location.href = res_redirect_uri;
				window.location.replace(res_redirect_uri);
			} catch (error) {
				setError(error.message);
			}
		}
	};

	const toggleAccount = () => setNewAccount((prev) => !prev);

	return (
		<>
			{/* <p>{state}</p>
			<p>{scope}</p>
			<p>{redirect_uri}</p>
			<p>{client_id}</p>
			<p>{response_type}</p> */}
			<div className="text-center">
				<h3>클로바 계정연동</h3>
				<p>
					계정이 없으신 경우 먼저{" "}
					<Link to="/signup" className="mb-5 font-link">
						회원가입
					</Link>{" "}
					후, 계정연동을 진행하시기 바랍니다.
				</p>
			</div>

			<form onSubmit={onSubmit} className="loginForm">
				<input
					name="email"
					value={email}
					onChange={onChange}
					type="email"
					placeholder="Email"
					required
					className="authInput"
				/>
				<input
					name="password"
					value={password}
					onChange={onChange}
					type="password"
					placeholder="Password"
					required
					className="authInput"
				/>
				<input className="authInput authSubmit" type="submit" value="로그인" />
				{error && <span className="authError">{error}</span>}
			</form>
		</>
	);
};

export default AuthForm4Clova;
