import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import Auth from "pages/Auth";
import Login from "pages/Login";
import Signup from "pages/Signup";

import Auth4Clova from "pages/Auth4Clova";
import Home from "pages/Home";
import Landing from "pages/Landing";

import Profile from "pages/Profile";

import Iot from "pages/Iot";

import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import OperationPolicy from "pages/OperationPolicy";

import Navigation from "./Navigation";
import Footer from "components/Footer";

const AppRouter = ({ isLoggedIn, userObj, refreshUser }) => {
	return (
		<Router>
			{isLoggedIn ? <Navigation userObj={userObj} /> : <Navigation />}
			<Routes>
				{isLoggedIn ? (
					<>
						<Route path="/" element={<Home userObj={userObj} />} />
						<Route
							path="/profile"
							element={<Profile userObj={userObj} refreshUser={refreshUser} />}
						/>
						<Route path="/iot" element={<Iot userObj={userObj} />} />
					</>
				) : (
					<>
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/login4clova" element={<Auth4Clova />} />
						<Route path="/" element={<Landing />} />
					</>
				)}
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
				<Route path="/operation-policy" element={<OperationPolicy />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
			<Footer />
		</Router>
	);
};

export default AppRouter;
