import React from "react";
import { Row, Col, Container } from "reactstrap";

// import imgMomssi from "images/About/imgMomssi.png";
// import imgHeaderBG from "images/About/imgHeaderBg.jpg";

function About() {
	return (
		<section
			className="section"
			// style={{
			// 	backgroundImage: `url(${imgHeaderBG})`,
			// 	backgroundPosition: "center center",
			// 	backgroundSize: "cover",
			// 	backgroundRepeat: "no-repeat",
			// 	backgroundColor: "black",
			// }}
		>
			<Container>
				<Row>
					<Col className="col-1"></Col>
					<Col
						style={{
							color: "white",
							paddingTop: "100px",
						}}
						md={6}
						className="about-header"
					>
						<p className="about-title">
							IoT 실습
							{/* <img
								src={imgMomssi}
								className="about-logo"
								alt="맘씨 아이콘 이미지"
								width="50px"
							/> */}
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default About;
