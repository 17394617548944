import { authService, firebaseInstance } from "fbase";
import AuthForm from "components/AuthForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const Signup = () => {
	const onSocialClick = async (event) => {
		const {
			target: { name },
		} = event;
		let provider;
		if (name === "google") {
			provider = new firebaseInstance.auth.GoogleAuthProvider();
		}

		const data = await authService.signInWithPopup(provider);
		// console.log(data);
	};

	return (
		<>
			<div className="authContainer">
				<AuthForm />

				<button onClick={onSocialClick} name="google" className="authBtn">
					구글 로그인 <FontAwesomeIcon icon={faGoogle} />
				</button>
			</div>
		</>
	);
};

export default Signup;
