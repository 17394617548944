import About from "../components/landing/About";
import Samples from "../components/landing/Samples";
import Process from "../components/landing/Process";
import AISpeaker from "components/landing/AISpeaker";
import Advertisement from "components/landing/Advertisement";
import Guide from "components/landing/Guide";

const Home = ({ userObj }) => {
	return (
		<>
			<About />
			{/* <Samples />
			<Guide />
			<Process />
			<AISpeaker />
			<Advertisement /> */}
		</>
	);
};

export default Home;
