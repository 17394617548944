import React from "react";
import { Row, Col, Container } from "reactstrap";
import DocsLink from "components/DocsLink";

function OperationPolicy() {
	return (
		<Container className="container-890">
			<div>
				<DocsLink />
				<Row>
					<Col className="mt-5 col-12">
						{" "}
						<h1>운영정책</h1>
						<p>
							따보 주식회사(이하 "회사")는 "맘씨"(이하 "서비스") 서비스의 운영
							기준과 회원이 서비스를 이용하면서 숙지해야 할 사항을 담고
							있습니다. 운영정책을 준수하지 않을 경우, 서비스 이용에 제한을 받을
							수 있으니 주의 깊게 읽어주시기 바랍니다. 본 운영정책에서 사용하는
							용어의 정의는 “맘씨” 이용약관을 참고하십시오. 회사는 더 나은
							서비스 제공을 위하여 수시로 운영정책을 변경할 수 있습니다.{" "}
						</p>
						<p>본 운영정책에 규정되지 않은 내용은 맘씨 이용약관을 따릅니다.</p>
						<p>
							회사는 본 개인정보처리방침을 이용자가 언제든지 쉽게 확인할 수
							있도록 공개하고 있습니다.
						</p>
					</Col>
				</Row>
				<Row>
					<Col className="mt-3 col-12">
						<h3>1. 가입</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								서비스는 이용자의 아기정보와 육아정보를 등록하고, 인터넷 상에서
								음성인식 스피커와 웹사이트 등과 같은 채널로 기록&확인할 수
								있도록 합니다. 또한 서비스 이용자와 광고주에 맞춤형 광고 플랫폼
								역할을 합니다.
							</li>
							<li>
								서비스 이용을 희망하는 사용자는 우선적으로 서비스에 회원가입
								페이지에서 이메일 주소 등을 입력하고 이용약관 및 개인정보 처리
								방침에 동의하여야 합니다.
								<br /> <br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										기존 회원과 동일한 닉네임 및 이메일 주소는 사용할 수
										없습니다.
									</li>
								</ol>
							</li>
							<li>
								“회사"는 아래 각 호에 해당하는 경우 회원의 가입을 취소할 수
								있습니다.
								<br /> <br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										필수 자료 및 정보를 제출하지 않았거나 허위로 제출한 경우
									</li>
									<li>
										과거 자격제한 조치를 받았던 회원이 이용계약 해지 후 다시
										가입을 신청하는 경우
									</li>
									<li>
										반사회적 또는 불법적 목적으로 서비스를 이용하는 것으로
										판단되는 경우
									</li>

									<li>
										기타 이유로 파트너사의 가입이 적합하지 않다고 판단되는 경우
									</li>
								</ol>
							</li>
						</ol>

						<h3>2. 서비스 이용</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								아기정보 추가하기
								<br /> <br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										아기정보를 추가하기 위해서는 아기의 성명, 성별, 생년일시,
										프로필 이미지를 저장합니다.
										<br /> <br />
										<ol style={{ listStyleType: "decimal" }}>
											<li>
												정보입력형태, 이미지 사이즈, 크기, 파일 포맷 등에 대한
												상세 내용은 서비스의 아기정보 추가하기 폼에 표기된
												내용을 따릅니다. 다만, 당사 사정에 의해 일부 제한되거나
												변동될 수 있습니다.
											</li>
										</ol>
									</li>
									<li>
										아기정보 추가폼에서의 기본 글꼴은 서비스 기본 설정값이며,
										회원 임의로 변경할 수 없습니다.
									</li>
									<li>
										등록된 아기들은 생년일시 데이터에 따라 아기를 선택할 수 있는
										버튼이 생성됩니다. 버튼 클릭시 해당 아기의 육아정보를 확인할
										수 있습니다.
									</li>
									<li>
										“회사"는 회원이 다음 각 호의 내용을 포함하여 아기정보를
										추가하는 경우, 별도의 사전 고지 없이 서비스 이용을
										차단하거나 삭제할 수 있으며, 나아가 회원 자격을 제한 또는
										상실하게 할 수 있습니다.
										<br /> <br />
										<ol style={{ listStyleType: "decimal" }}>
											<li>
												욕설, 비속어, 은어 등이나 타인에게 성적 수치심이나
												불쾌감·혐오감을 유발할 수 있는 내용
											</li>
											<li>
												지역·장애·인종·출신국가·성별·나이·직업·종교 등을
												차별하거나 이에 대한 편견을 조장하는 내용
											</li>
											<li>불법적인 사행성, 도박 관련 내용</li>
											<li>
												불법 제품 또는 인터넷에서 판매 금지된 물품을 홍보하거나
												이를 암시하는 내용
											</li>
											<li>
												타인의 지식재산권(특허권, 상표권, 저작권 등)을
												침해하거나 국내외에서 널리 알려진 타인의 상표, 상호 등을
												도용한 내용
											</li>
											<li>
												투자상품으로 해석되거나 투자상품성 요소가 내포된 내용
											</li>
											<li>
												범법 행위에 대한 동기 부여 및 실행에 도움이 되는 내용
											</li>
											<li>특정 인물이나 서비스의 명칭 등을 사칭하는 내용</li>
											<li>
												그 외 “회사”가 판단했을 때 상식과 사회 통념에 반하는
												비정상적인 내용
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								육아일기
								<br /> <br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										육아일기에서는 아기정보와 육아정보를 관리할 수 있습니다.
									</li>
									<li>
										아기정보 설정 버튼을 통해 아기정보와 육아정보 입력에 필요한
										기본값을 수정할 수 있습니다.
									</li>

									<li>
										수유, 배변, 수면 버튼을 통해 기본값을 설정된 정보에 따라
										한번의 클릭으로 해당 정보가 추가됩니다.
									</li>

									<li>
										일자별로 기록된 육아정보를 선택하면 해당 육아정보를
										수정/삭제 할 수 있습니다.{" "}
									</li>
								</ol>
							</li>

							<li>
								마이 페이지
								<br /> <br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										마이페이지에서는 회원의 닉네임 정보를 조회할 수 있습니다.
									</li>
									<li>수정 버튼을 통해 회원정보 수정을 할 수 있습니다.</li>
								</ol>
							</li>
							<li>
								회원 탈퇴
								<br /> <br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										마이 페이지에 있는 회원탈퇴 버튼을 통해 회원탈퇴 기능을
										이용할 수 있습니다.
									</li>
									<li>
										회원탈퇴시 저장된 아기정보와 육아정보와 파일들이 삭제됩니다.
									</li>
								</ol>
							</li>
						</ol>
						<h3>3. 문의</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								그 외 서비스 이용 관련 문의는 sy@ddabo.com으로 부탁드립니다.
							</li>
						</ol>
						<hr />
						<p>본 약관은 2022년 05월 16일부터 적용됩니다.</p>
					</Col>
				</Row>
			</div>
		</Container>
	);
}

export default OperationPolicy;
