import React from "react";
import { Row, Col, Container, Table } from "reactstrap";
import DocsLink from "components/DocsLink";

function PrivacyPolicy() {
	return (
		<Container className="container-890">
			<div>
				<DocsLink />
				<Row>
					<Col className="mt-5 col-12">
						{" "}
						<h1>개인정보 처리방침</h1>
						<p>
							따보 주식회사(이하 "회사")는 맘씨(이하 '서비스') 이용・제공을 위해
							이용자의 '동의를 기반으로 개인정보를 수집・이용 및 제공’하고
							있으며, 개인정보 보호와 관련된 대한민국의 법령 및 규정,
							가이드라인을 준수하고 있습니다.
						</p>
						<p>
							회사는 본 개인정보처리방침을 이용자가 언제든지 쉽게 확인할 수
							있도록 공개하고 있습니다.
						</p>
					</Col>
				</Row>
				<Row>
					<Col className="mt-3 col-12">
						<h3>제 1 조 (개인정보 수집과 목적)</h3>
						<p>
							회사에서 수집하는 개인정보 항목과 각 목적은 다음과 같으며, 서비스
							이용에 필요한 최소한의 개인정보만 수집하고 있습니다. 또한, 회사는
							명시한 목적으로만 이용자의 개인정보를 처리하며, 명시한 목적 이
							외의 용도로는 이용하지 않습니다. 명시된 이용 목적이 변경되는
							경우에는 이용자에게 그 사실을 알리고 별도의 동의를 받는 등 필요한
							조치를 이행할 예정입니다.
						</p>
						<h4>1. 수집하는 개인정보의 항목</h4>
						<Table>
							<thead>
								<tr>
									<th>구분</th>
									<th>세부 항목</th>
									<th>필수 여부</th>
									<th>수집 방법</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">회원가입 및 서비스 이용</th>
									<td>이메일, 패스워드, 닉네임, 프로필이미지</td>
									<td>필수</td>
									<td>서비스 가입</td>
								</tr>
								<tr>
									<th scope="row">아기 정보</th>
									<td>
										아기 성명, 생일, 성별, 몸무게, 키, 프로필이미지, 수유, 배변,
										수면 정보
									</td>
									<td>선택</td>
									<td>아기정보 추가 양식, AI 스피커 또는 웹사이트</td>
								</tr>
								<tr>
									<th scope="row">자동으로 수집되는 정보</th>
									<td>접근 로그, IP 정보, 쿠키, 불량 및 부정 이용 기록</td>
									<td>필수</td>
									<td>자동수집</td>
								</tr>
							</tbody>
						</Table>
						<h4>2. 개인정보 수집・이용 목적</h4>
						<li>
							이용자 식별, 계정의 접근 및 사용 권한 관리, 이용자 정보 관리,
							민원처리, 각종 고지사항 전달
						</li>
						<li>신규 서비스 개발, 다양한 서비스 제공</li>
						<li>
							서비스 안정성 확보, 안전한 서비스 제공, 법률 및 서비스 이용약관
							위반 행위 제한 등
						</li>
						<h4>3. 개인정보 수집 방법</h4>

						<p>
							{" "}
							온라인 서비스 회원가입 및 이용 과정에서 개인정보 수집에 동의하고
							직접 정보를 입력하는 경우 고객문의 과정에서 수집되는 이메일 등
							기타 자동수집 장치를 통한 정보
						</p>

						<h3>제 2 조 (개인정보의 보유 및 이용기간)</h3>
						<p>
							회사는 이용자의 개인정보를 다음과 같이 보유 및 이용하고 있습니다.
							이 외의 이벤트 등을 위해 개인정보를 수집하는 경우 동의 받은
							개인정보 보유 및 이용기간에 따릅니다.
						</p>

						<h4>1. 개인정보 보유 및 이용기간</h4>
						<Table>
							<thead>
								<tr>
									<th>구분</th>
									<th>보유사유</th>
									<th>보유기간</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>회원정보</td>
									<td>회원 식별 및 관리, 서비스 이용 및 제공</td>
									<td>회원탈퇴시까지</td>
								</tr>
								<tr>
									<td>아기정보</td>
									<td>육아일기 서비스 이용 및 관리</td>
									<td>회원탈퇴시까지(규정위반, 요청 시 파기)</td>
								</tr>
							</tbody>
						</Table>

						<h3>제 3 조 (개인정보처리의 제공)</h3>
						<p>
							개인정보를 처리 목적으로 명시한 범위를 초과하여 제3자에게 제공하지
							않습니다. 다만, 다음 각 호의 경우에는 제3자에게 제공할 수
							있습니다.
						</p>

						<li>이용자로부터 별도의 동의를 받은 경우</li>
						<li>다른 법률에 특별한 규정이 있는 경우</li>

						<h3>제 4 조 (개인정보의 파기)</h3>
						<p>
							1. 회사는 개인정보의 보유 및 이용 기간의 경과, 처리 목적 달성 등
							개인정보가 불필요하게 되었을 때는 지체없이 해당 개인정보를
							파기합니다.{" "}
						</p>
						<p>
							{" "}
							2. 이용자로부터 동의 받은 개인정보 보유 및 이용 기간이 지나거나
							처리 목적이 달성되었음에도 불구하고 법령에 따라 개인정보를 계속
							보존하여야 하는 경우에는, 해당 개인 정보를 별도의 데이터베이스(DB)
							로 옮기거나 보관장소를 달리하여 보존합니다.{" "}
						</p>
						<p>3.개인정보 파기 방법은 다음과 같습니다. </p>

						<li>
							전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 영구
							삭제
						</li>
						<li>종이 문서에 기록.저장된 개인정보는 분쇄기로 분쇄하거나 소각</li>

						<h3>제 5 조 (개인정보의 기술적.관리적 보호 대책)</h3>
						<p>
							회사는 이용자의 개인정보를 처리하면서 개인정보가 분실, 도난, 누출,
							변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
							기술적/관리적 대책을 마련하고 있습니다.
						</p>
						<p>
							1. 관리적 조치
							<br />
							① 개인정보 내부관리계획 수립 및 시행
							<br />② 개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는
							직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는
							대책을 시행하고 있습니다.
						</p>
						<p>
							2. 기술적·물리적 조치
							<br />
							① 개인정보처리시스템 접근권한 관리 : 개인정보를 처리하는 시스템에
							대한 접근권한을 최소한의 인원으로 제한하고 개인정보에 대한
							접근통제를 위하여 필요한 조치를 하고 있습니다.
							<br />
							② 개인정보의 암호화 : 이용자의 정보를 안전하게 송수신하기 위하여
							암호화 통신(HTTPS)를 사용하고 있습니다.
							<br />
						</p>

						<h3>제 6 조 (이용자 및 법정대리인의 권리·의무 및 행사 방법)</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								이용자는 회사에 대해 언제든지 개인정보 열람・정정・삭제・처리
								정지 요구 등의 권리를 행사할 수 있습니다. 다만, 개인정보보호법
								등 관계 법령에서 정하는 바에 따라 이용자의 개인정보
								열람・정정・삭제・처리 정지 요구 등의 권리 행사가 제한될 수
								있습니다.
							</li>
							<li>
								만 14세 미만의 아동은 서비스 가입을 할 수 없으며, 서비스 이용 중
								만 14세 미만의 아동이 가입된 것을 인지하는 경우 지체없이 해당
								이용자의 가입을 해지할 수 있습니다.
							</li>
							<li>
								만 14세 미만 아동이 가입한 경우 회사에 가입해지를 요청하거나
								서비스 설정 메뉴를 통하여 탈퇴가 가능합니다.
							</li>
							<li>
								이용자는 개인정보(수신 가능한 이메일 등)를 정확하게 입력하여
								예기치 못한 사고(계정분실, 장기미사용자 데이터 삭제 등)를 예방해
								주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는
								사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등
								허위정보를 입력할 경우 서비스 회원 자격이 정지될 수 있습니다.
								이용자 개인정보와 관련한 아이디의 비밀번호에 대한 보안유지책임은
								해당 이용자 자신에게 있습니다. 비밀번호는 암화화되어 저장되기
								때문에 서비스 운영자도 알 수 없습니다.
							</li>

							<li>
								이용자의 권리 행사는 전자우편 등을 통하여 하실 수 있으며, 회사는
								이에 대해 지체없이 조치하겠습니다.
							</li>
							<li>
								제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자
								등 대리인을 통하여서 하실 수 있습니다. 이 경우, 이용자의 권리를
								위임받은 위임장을 제출하여야 합니다.
							</li>
							<li>
								개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집
								대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
							</li>
							<li>
								회사는 이용자 이용 권리에 따른 열람의 요구, 정정・삭제의 요구,
								처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
								대리인인지를 확인합니다.
							</li>
							<li>
								이용자는 권리행사의 거부 등에 대하여 불복 시 회사에 이의제기를
								하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
							</li>
						</ol>
						<p>
							서비스에서는 비밀번호에 대해 어떠한 방법으로도 이용자에게
							직접적으로 질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지
							않도록 각별히 주의하시기 바랍니다.
						</p>

						<h3>
							제 7 조 (개인정보 자동수집 장치의 설치.운영 및 그 거부에 관한
							사항)
						</h3>
						<p>
							회사는 이용자의 서비스 편의를 제공하기 위해 이용 정보를 저장하고
							수시로 불러오는 쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가
							고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의
							정보입니다. <br />
							<br />
							1. 쿠키의 사용 목적 <br />
							쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱
							빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에
							활용합니다. 이를 통해 이용자는 더욱 손쉽게 서비스를 이용할 수 있게
							됩니다.
							<br />
							<br />
							2. 쿠키의 설치.운영 및 거부 <br />
							이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한
							쿠키의 저장을 거부하거나 삭제할 수 있습니다.
							<br />
							<br />
							3. 쿠키 설정 거부 방법 <br />① Internet Explorer : 도구 메뉴 선택{" "}
							{">"} 인터넷 옵션 선택 {">"} 개인정보 탭 클릭 {">"} 고급 개인정보
							설정 {">"} 쿠키 수준 설정
							<br />② Chrome : 설정 메뉴 선택 {">"} 고급 설정 표시 선택 {">"}{" "}
							개인정보 및 보안 {">"} 콘텐츠 설정 선택 {">"} 쿠키 수준 설정
							<br />③ Safari : 환경설정 메뉴 선택 {">"} 개인정보 탭 선택 {">"}{" "}
							쿠키 및 웹 사이트 데이터 수준 설정
							<br />④ FireFox: 도구 메뉴 선택 {">"} 환경 설정 메뉴 선택 {">"}{" "}
							개인 정보 및 보안 탭 선택 {">"} 콘텐츠 차단 설정
							<br />⑤ Opera: 도구 메뉴 선택 {">"} 설정 메뉴 선택 {">"} 개인 정보
							보호 및 보안 탭 선택 {">"} 쿠키 수준 설정
							<br />
						</p>

						<h3>제 8 조 (개인정보 보호 책임자 및 담당 부서)</h3>
						<p>
							1. 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을
							처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를
							지정하고 있습니다.
							<br />
							<br />
							- 이름 : 이상용
							<br />
							- 이메일 : sy@ddabo.com
							<br />
							<br />
							2. 이용자가 회사의 서비스를 이용하며 발생하는 모든 개인정보보호
							관련 민원은 개인정보 보호 책임자 및 담당 부서로 문의하실 수
							있습니다. 회사는 이용자의 문의 사항에 대해 충분한 답변을 드릴 수
							있도록 최선을 다하겠습니다
							<br />
						</p>

						<h3>제 9 조 (권익침해 구제방법)</h3>
						<p>
							개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에
							문의하실 수 있습니다.
							<br />
							<br />
							개인정보 침해신고센터 (한국인터넷진흥원 운영)
							<br />
							- 홈페이지 : privacy.kisa.or.kr
							<br />
							- 전화 : (국번없이) 118
							<br />
							<br />
							개인정보 분쟁조정위원회
							<br />
							- 홈페이지 : www.kopico.go.kr
							<br />
							- 전화 : (국번없이) 1833-6972
							<br />
							<br />
							대검찰청 사이버범죄수사단
							<br />
							- 홈페이지 : www.spo.go.kr
							<br />
							- 전화 : 02-3480-3573
							<br />
							<br />
							경찰청 사이버안전국
							<br />
							- 홈페이지 : cyberbureau.police.go.kr
							<br />
							- 전화 : (국번없이) 182
							<br />
						</p>

						<h3>제 10 조 (링크 사이트에 대한 책임)</h3>
						<p>
							회사는 이용자에게 다른 외부사이트로 연결되는 링크를 제공할 수
							있습니다. 이 경우 회사는 외부사이트에 대한 통제권이 없으므로
							이용자가 외부사이트로부터 제공받은 서비스나 자료의 유용성, 진실성,
							적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부사이트의
							개인정보처리방침은 회사와 무관하므로 해당 외부사이트의 정책을
							확인하시기 바랍니다.
						</p>

						<h4>개인정보처리방침이 변경되는 경우</h4>
						<p>
							법률이나 서비스의 변경에 따라 개인정보보호방침의 내용 수정이 있을
							시에는 변경사항 시행일의 7일 전부터 당사 홈페이지의 공지사항을
							통하여 고지합니다. 회사는 이용자 여러분의 정보를 소중히 생각하며,
							이용자가 더욱 안심하고 서비스를 이용할 수 있도록 최선의 노력을
							다할 것을 약속드립니다.
						</p>
						<hr />
						<p>본 개인정보 처리방침은 2019년 10월 14일부터 적용됩니다.</p>
					</Col>
				</Row>
			</div>
		</Container>
	);
}

export default PrivacyPolicy;
