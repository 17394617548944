import React from "react";
import { Row, Col, Container } from "reactstrap";
import DocsLink from "components/DocsLink";

function TermsOfService() {
	return (
		<Container className="container-890">
			<div>
				<DocsLink />

				<Row>
					<Col className="mt-5 col-12">
						{" "}
						<h1>서비스 이용약관</h1>
					</Col>
				</Row>
				<Row>
					<Col className="mt-3 col-12">
						<h3>제 1 조 (목적)</h3>
						<p>
							본 약관은 이용자(이하 “회원")가 따보 주식회사 (이하 “회사”)가
							제공하는 맘씨 서비스(이하 “서비스”)에 가입하고 이를 이용함에 있어
							회사와 회원의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
						</p>
						<h3>제 2 조 (용어의 정의)</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								“서비스”라 함은 이용자가 아기정보를 등록하고, 음성인식 스피커
								또는 웹사이트로 아기의 육아정보를 기록하고 확인할 수 있도록
								서비스를 제공합니다. 또한, 서비스 이용자와 광고주에 맞춤형 광고
								플랫폼 역할을 합니다.
							</li>
							<li>
								“육아정보”라 함은 아기가 수유, 배변, 수면정보를 의미합니다.
							</li>

							<li>
								“음성인식 스피커”라 함은 음성명령으로 서비스를 요청할 수 있는
								기기입니다.
							</li>
							<li>
								"회원"이라 함은 본 약관에 따라 회사와 이용계약을 체결하고,
								회사가 제공하는 서비스를 이용하는 개인 또는 법인을 말합니다.
							</li>
							<li>
								“계정"이라 함은 서비스에서 본인을 식별하기 위해 생성된 계정으로
								본 약관에 동의하고 회사와 이용계약을 체결한 회원에게 부여되는
								서비스 계정을 말합니다.
							</li>
							<li>
								“광고주"라 함은 맘씨 비즈니스 회원 약관에 동의하여 비즈니스
								회원으로 가입한 후 본 약관에서 정한 절차에 따라 본 서비스를 통해
								광고 콘텐츠를 제공하는 자를 말합니다.
							</li>
						</ol>
						<p>
							본 약관에서 사용하는 용어의 정의에서 정하는 것을 제외하고는 관계
							법령 및 일반적인 상관례에 의합니다.
						</p>

						<h3>제 3 조 (약관의 게시와 개정)</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
								화면에 게시합니다.
							</li>
							<li>
								회사는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및
								정보보호 등에 관한 법률(이하 "정보통신망법")", “개인정보보호법",
								“특정금융거래정보 보고 및 이용 등에 관한 법률(이하 “특금법")” 등
								관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
							</li>
							<li>
								회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
								현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 15일
								전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한
								약관의 개정의 경우에는 공지 외에 문자메세지, 일정기간 서비스내
								전자우편, 로그인 팝업창 등의 전자적 수단을 통해 따로 명확히
								통지하도록 합니다.
							</li>
							<li>
								회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 15일
								기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는
								뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
								의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로
								봅니다.
							</li>
							<li>
								회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의
								내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수
								있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는
								경우에는 회사가 이용계약을 해지할 수 있습니다.
							</li>
						</ol>
						<h3>제 4 조 (약관 외 준칙) </h3>
						<p>
							본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는
							대한민국의 관련 법령 또는 회사가 정한 서비스의 개별 이용약관과
							운영정책 및 규칙 등(이하 ‘세부지침')의 규정을 따릅니다. 또한 본
							약관과 세부지침의 내용이 충돌할 경우 세부지침에 따릅니다.
						</p>
						<h3>제 5 조 (이용계약 체결) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 약관의
								내용에 대하여 동의를 한 다음 회원가입 신청을 하고 회사가 이러한
								신청에 대하여 승낙함으로써 체결됩니다.
							</li>
							<li>
								회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을
								원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에
								대하여는 승낙을 하지 않거나 승낙 후에라도 이용계약을 해지할 수
								있습니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이
										있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로
										함
									</li>
									<li>허위로 정보를 기재하거나 타인의 명의를 이용한 경우</li>
									<li>
										14세 미만 아동이 법정 대리인(부모 등)의 동의를 얻지 아니한
										경우
									</li>
									<li>
										회원 및 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타
										규정한 제반 사항을 위반하며 신청하는 경우
									</li>
									<li>
										회원의 거주지에서 효력이 있는 대한민국 외의 법률에 따라 본
										서비스 이용행위가 해당 법률의 위반을 구성하거나 구성할
										위험이 있는 경우
									</li>
									<li>
										반사회적 또는 불법적 목적으로 서비스를 이용하려는 것으로
										판단되는 경우
									</li>
									<li>
										가입신청자가 기존의 서비스 탈퇴 신청을 한 후 삼십(30)일이
										경과하지 않은 상태에서 동일한 아이디로 가입 신청을 하는 경우
									</li>
									<li>
										필수 자료 및 정보를 제출하지 않았거나 허위로 제출한 경우
									</li>
									<li>
										기타 이유로 파트너사의 가입이 적합하지 않다고 판단되는 경우
									</li>
								</ol>
							</li>
							<li>
								제1항에 따른 신청에 있어 회사는 회사 자체 또는 전문기관을 통한
								법인확인 및 본인인증을 요청할 수 있습니다.
							</li>
							<li>
								회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
								문제가 있는 경우에는 승낙을 유보할 수 있습니다.
							</li>
							<li>
								제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나
								유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록
								합니다.
							</li>
							<li>
								이용계약의 성립 시기는 가입신청자가 약관에 동의한 후 회원가입
								신청을 하고 회사가 이를 검토하고 승낙한 시점으로 합니다.
							</li>
						</ol>

						<h3>제 6 조 (회원정보의 변경) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회원은 제5조에 따른 이용계약 체결 시 기재한 정보를 내 정보
								화면을 통하여 언제든지 열람할 수 있으며, 정보 변경에 대하여
								요청할 수 있습니다.
							</li>
							<li>회원의 정보변경 등은 회사의 운영정책에 의합니다.</li>
						</ol>
						<h3>제 7 조 (개인정보보호 의무) </h3>
						<p>
							회사는 “개인정보보호법", "정보통신망법" 등 관계 법령이 정하는 바에
							따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호
							및 사용에 대해서는 관련 법 및 회사의 개인정보처리방침이
							적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는
							회사의 개인정보처리방침은 적용되지 않습니다.
						</p>
						<h3>제 8 조 ("회원"의 “계정”등 관리에 대한 의무) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>회원의 가입방법은 회사의 운영정책에 의합니다.</li>
							<li>
								회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 제3자가
								이용하도록 하여서는 안 됩니다.
							</li>
							<li>
								회사는 회원의 계정 등 접근 정보가 분실, 도용 혹은 공개되어
								회원에게 발생한 손해에 대하여 책임지지 않습니다. 다만, 회사의
								고의 또는 과실에 의한 경우에는 그러하지 않습니다.
							</li>
						</ol>
						<h3>제 9 조 ("회원"에 대한 통지) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사는 서비스를 운영함에 있어 서비스와 관련한 정보 등을 회원에게
								통지하는 경우 회사가 보유한 회원의 전자우편 주소로의 전자우편
								발송, 핸드폰 SMS 발송, 서비스 내 팝업 등 합리적으로 가능한
								방법으로 회원에게 개별 통지합니다.
							</li>
							<li>
								회사는 회원 전체에 대한 통지의 경우 7일 이상 서비스 내 공지사항
								등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
							</li>
						</ol>

						<h3>제 10 조 ("회사"의 의무) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사는 관련 법과 이 약관이 금지하거나 일반 상식에 위배되는
								행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기
								위하여 최선을 다하여 노력합니다.
							</li>
							<li>
								회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를
								위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공지하고
								준수합니다.
							</li>
							<li>
								회사는 서비스 이용과 관련하여 발생하는 회원의 불만 또는 피해구제
								요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
								구비합니다.
							</li>
							<li>
								회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나
								불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이
								제기한 의견이나 불만사항에 대해서는 전자우편 등을 통하여
								회원에게 처리 과정 및 결과를 전달합니다.
							</li>
						</ol>
						<h3>제 11 조 ("회원"의 의무) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회원은 다음 행위를 하여서는 안 됩니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>신청 또는 변경 시 허위 내용의 등록</li>
									<li>타인의 정보도용</li>
									<li>회사가 게시한 정보의 변경</li>
									<li>
										회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
										또는 게시
									</li>
									<li>회사와 기타 제3자의 저작권 등 지식재산권에 대한 침해</li>
									<li>
										회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는
										행위
									</li>
									<li>
										외설 또는 폭력적인 메시지, 화상, 음성, 기타 사회질서에
										위반하는 정보를 서비스에 공개 또는 게시하는 행위
									</li>
									<li>
										회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
									</li>
									<li>다단계, 유사수신 등 불법적인 행위</li>
									<li>기타 불법적이거나 부당한 행위</li>
								</ol>
							</li>
							<li>
								회원은 관계법, 이 약관의 규정, 운영정책, 이용안내 및 서비스와
								관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
								하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
							</li>
							<li>
								회원은 서비스를 사용함에 있어서 제3자에게 권한을 양도하거나
								서비스 이용에 대한 대가(금전, 정보 제공 등 일체의 유·무형의
								대가)를 제공받는 행위를 하여서는 안됩니다.
							</li>
							<li>
								회원은 회사의 사전 동의 없이 서비스의 결과데이터(회원의 정보,
								서비스 내용 등 일체의 데이터를 포함한다)를 본 약관에서 허용한
								범위를 넘어서서 무단 복제, 저장, 가공, 배포하거나 제3자에게
								제공하여서는 안 됩니다.
							</li>
						</ol>
						<h3>제 12 조 ("서비스"의 제공 등) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사는 회원에게 아래와 같은 서비스를 제공합니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>아기정보&육아정보 기록/관리</li>
									<li>
										기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해
										회원에게 제공하는 일체의 서비스
									</li>
								</ol>
							</li>
							<li>
								전항에 따른 서비스의 종류 및 내용의 상세범위는
								홈페이지(https://www.momc.kr)에서 정한 바에 따릅니다.
							</li>
							<li>
								회사는 서비스 제공함에 있어서 서비스의 범위, 이용 가능시간, 이용
								가능 횟수 등을 지정할 수 있으며, 회원은 회사가 정하는 서비스
								제공 조건에 따라서만 서비스를 이용할 수 있습니다.
							</li>

							<li>
								회사는 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장, 통신
								두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을
								일시적으로 중단할 수 있습니다. 이 경우 회사는 제9조["회원"에
								대한 통지]에 정한 방법으로 회원에게 통지합니다. 다만, 회사가
								사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
								있습니다.
							</li>
							<li>
								회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
								정기점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다. 또한,
								정기점검 시 서비스 이용에 일부 또는 전체에 대하여 제한이 발생할
								수 있으며, 회사의 고의 또는 과실이 아닌 한 그로 인해 발생한
								손해에 대해서는 책임을 지지 않습니다.
							</li>
							<li>
								회원은 서비스를 통해 육아정보를 입력 또는 등록한 정보를 기반으로
								저장할 수 있습니다. 입력 및 등록한 정보의 정확성에 대한 책임은
								회원에게 있으며, 회사는 회원이 잘못 입력 또는 등록한 정보로
								인하여 발생할 수 있는 문제에 대해서 책임을 지지 않습니다.
							</li>
							<li>
								회사는 회원이 간편하게 육아정보를 저장하고 관리할 수 있도록
								기술을 제공하는 것으로 회원이 직접 육아정보를 발행하고 관리하는
								것을 원칙으로 하며, 회원의 육아정보 저장 및 관리를 대행하거나
								위탁하여 운영하지 않습니다.
							</li>
							<li>
								서비스와 연계된 제3자의 서비스 등의 장애 또는 점검 등의 사유로
								서비스 내 일부 기능에 제한이 발생하는 문제에 대해서는 회사의
								고의, 과실이 없는 한 어떠한 책임을 지지 않습니다. 이 경우,
								회원에게 해당 사실을 공지 또는 통지합니다.
							</li>
						</ol>
						<h3>제 13 조 ("서비스"의 변경 및 중단 등) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사는 다음과 같은 경우 또는 이에 해당하는 사실이 존재한다고
								의심할 만한 합리적 이유가 있는 경우에 운영상, 기술상의 필요에
								따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>서비스를 통해 불법적인 행위가 발생한 경우</li>
									<li>
										컴퓨터 등 정보통신설비의 보수점검, 장비의 교체 및 고장,
										통신의 두절 등의 사유가 발생한 경우
									</li>
									<li>
										서비스를 위한 설비 등의 유지보수 등으로 인한 부득이한 사정이
										있는 경우
									</li>
									<li>
										정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
										서비스 이용에 지장이 있는 경우
									</li>
									<li>
										전국 또는 지역 등 범위적인 네트워크 장애가 발생하는 경우
									</li>
									<li>회사의 운영정책과 반하는 회원의 행위가 발생하는 경우</li>
									<li>
										경영상 이유 등으로 인하여 서비스를 제공할 수 없는 부득이한
										사정이 발생한 경우
									</li>
									<li>
										기타 천재지변, 국가비상사태, 각 국가의 법령/정책 상의 규제
										또는 제한 등 불가항력적 사유가 있는 경우
									</li>
								</ol>
							</li>
							<li>
								서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
								변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
								해당 서비스 초기화면에 게시하거나 제9조["회원"에 대한 통지]에
								따라 통지합니다. 단, 회사가 통제할 수 없는 사유 또는 긴급사유
								등으로 인하여 회원에 대한 공지 또는 통지가 불가능한 경우에는
								그러하지 아니합니다.
							</li>
							<li>
								회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의
								필요상 수정, 중단, 변경할 수 있으며, 회원은 서비스 변경에
								동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할
								수 있습니다.
							</li>
							<li>
								회사는 본 서비스의 변경, 중단으로 발생하는 문제에 대해서 회사의
								고의 또는 과실이 없는 한 어떠한 책임도 지지 않습니다.
							</li>
						</ol>

						<h3>제 14 조 ("서비스"의 저작권 등) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사가 제공하는 서비스 및 관련 프로그램의 저작권은 회사에게
								있습니다.
							</li>
							<li>
								회원은 서비스 이용 시 회사 또는 제3자의 저작권을 침해하여서는 안
								됩니다.
							</li>
							<li>
								회사의 서비스 이용 승낙으로 인하여 회원이 서비스 결과에 대한
								저작권 또는 사용권을 획득한 것은 아닙니다.
							</li>
						</ol>
						<h3>제 15 조 (계약해제, 해지 등) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회원은 언제든지 탈퇴 신청을 회사가 정하는 운영정책에 따라 회사에
								해지의사를 통지함으로써 이용계약을 해지할 수 있으며 이용계약은
								회원의 해지의사가 회사에 도달한 때에 종료됩니다.
							</li>
							<li>
								회사는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수
								있습니다. 이 경우 회사는 회원에게 전자우편, 전화, 기타의 방법을
								통하여 해지사유를 밝혀 해지의사를 통지합니다. 이 경우 회사는
								해당 회원에게 사전에 해지사유에 대한 의견진술의 기회를
								부여하여야 하며, 이용계약은 회사의 해지의사가 회원에게 도달한
								때에 종료됩니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										회원에게 제5조 제2항에서 정하고 있는 이용 신청의 승낙 거부
										사유가 있음이 확인된 경우
									</li>
									<li>회원이 본 약관 및 운영정책에 위배되는 행위를 한 경우</li>
									<li>
										불법프로그램의 제공 및 서비스 운영 방해, 불법통신 및 해킹,
										악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령에
										위배되는 행위를 한 경우
									</li>
									<li>
										회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를
										하거나 그러한 행위를 시도한 경우
									</li>
									<li>
										기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할
										필요가 있다고 인정하는 경우
									</li>
								</ol>
							</li>
							<li>
								본 조에 따른 이용계약 해지 시 회원이 서비스 이용을 통해 만든
								보이스, 보이스 앨범 관리 및 설정 등 모든 정보가 소멸되며, 회사는
								이에 대해 별도로 보상하지 않습니다.
							</li>
							<li>
								이용계약 해지가 완료되는 경우 회원의 모든 정보가 삼십(30)일 이내
								삭제됩니다.
							</li>
							<li>
								회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및
								운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련
								법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
							</li>
							<li>
								회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를
								표시하고 이용계약을 해지할 수 있습니다.
							</li>
							<li>
								회사는 본 서비스의 변경, 중단으로 발생하는 문제에 대해서 회사의
								고의 또는 과실이 없는 한 어떠한 책임도 지지 않습니다.
							</li>
						</ol>
						<h3>제 16 조 ("육아정보"의 저장 및 이용제한 등 ) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								서비스에서 아기정보, 육아정보 저장 및 관리 방법 등의 상세 규정은
								운영정책에 의합니다.
							</li>
							<li>
								회원은 서비스를 통해 회사가 정하는 기준에 따라 아기정보와
								육아정보를 저장하고 관리할 수 있습니다. 다만, 다음과 같은 정보를
								포함하고있는 경우에는 사전 고지 없이 육아정보 또는 아기정보
								전체에 대하여 제한하거나 삭제될 수 있습니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										욕설, 비속어, 은어 등이나 타인에게 성적 수치심이나
										불쾌감·혐오감을 유발할 수 있는 내용
									</li>
									<li>
										지역·장애·인종·출신국가·성별·나이·직업·종교 등을 차별하거나
										이에 대한 편견을 조장하는 내용
									</li>
									<li>불법적인 사행성, 도박 관련 내용</li>
									<li>
										불법 제품 또는 인터넷에서 판매 금지된 물품을 홍보하거나 이를
										암시하는 내용
									</li>
									<li>
										타인의 지식재산권(특허권, 상표권, 저작권 등)을 침해하거나
										국내외에서 널리 알려진 타인의 상표, 상호 등을 도용한 내용
									</li>
									<li>타인의 초상권을 침해하는 내용</li>
									<li>
										투자상품으로 해석되거나 투자상품성 요소가 내포된 내용을
										포함하여 증권성을 띄는 내용
									</li>
									<li>범법 행위에 대한 동기 부여 및 실행에 도움이 되는 내용</li>
									<li>특정 인물이나 서비스의 명칭 등을 사칭하는 내용</li>
									<li>
										기타 회사가 합리적인 판단에 기하여 상식과 사회 통념에 반하는
										등의 비정상적인 내용
									</li>
								</ol>
							</li>
							<li>
								다음 각 호에 따른 서비스 이용에 제한이 발생하는 경우 회원이 기존
								보유 중이던 아기정보, 육아정보 등의 정보는 이용이 제한되거나
								삭제됩니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>제4항에 해당하는 경우로 삭제가 필요한 경우</li>
									<li>
										제13조, 제15조, 제16조에 따라 서비스 이용계약이 제한되거나
										해지되는 경우
									</li>
									<li>
										부당한 방법 또는 이와 유사한 행위 등으로 인해 서비스 이용에
										제한이 발생하는 경우
									</li>
								</ol>
							</li>
							<li>
								회원이 서비스를 통해 저장한 아기정보/육아정보에 대한 내용, 목적
								등에 대한 보증 및 이행 의무는 회원에게 있으며, 회사는 회원이
								저장한 아기정보/육아정보에 대하여 어떠한 보증 및 책임을 가지지
								않습니다.
							</li>
							<li>
								회원은 전 항에도 불구하고 의무를 이행하지 않거나 회원의 과실
								또는 변덕으로 인하여 발생할 수 있는 정보유출, 명예훼손 기타
								위반행위로 인한 문제에 대한 모든 책임은 전적으로 회원에게
								있습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지
								않습니다.
							</li>
						</ol>
						<h3>제 17 조 (이용제한 등) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사는 다음 각 호에 해당하는 경우, 경고, 정지, 영구이용정지,
								이용계약의 해지 등으로 서비스 이용을 단계적으로 제한할 수
								있습니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>제16조 제2항에 해당하는 경우</li>
									<li>해킹 및 사기 사고가 발생한 경우</li>
									<li>명의 도용으로 의심되는 경우</li>
									<li>국가기관이 관련 법령에 따라 서비스 제한을 요청한 경우</li>
									<li>
										{" "}
										자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고
										있다고 합리적으로 의심되는 경우
									</li>
									<li>회원으로 가입된 고객이 14세 미만으로 확인된 경우</li>
									<li>
										회원이 약관의 의무를 위반하거나 서비스의 정상적인 운영을
										방해한 경우
									</li>
									<li>
										기타 각 호에 준하는 사유가 발생하거나 그러한 사유 발생의
										방지를 위한 조치가 필요한 경우
									</li>
								</ol>
							</li>
							<li>
								전항에 따른 서비스 이용에 제한이 발생하는 경우,
								제12조[“서비스"의 제공] 제1항의 내용을 포함하여 저장된 모든
								보이스/보이스앨범의 사용제한 조치가 적용될 수 있습니다.
							</li>
							<li>
								회사는 전항에도 불구하고, "저작권법" 및 "컴퓨터프로그램보호법"을
								위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한
								불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과
								같이 관련법을 위반한 경우를 포함하여 회사가 긴급하게 이용을
								중지해야 할 필요가 있다고 인정하는 경우에는 즉시 이용제한 또는
								영구이용정지를 할 수 있습니다.
							</li>
							<li>
								이용제한 또는 영구이용정지가 발생한 경우 회사에서 정하는
								해제조건을 갖추었을 경우에 한해 회원의 이용제한된 상태를 해제할
								수 있습니다.
							</li>
							<li>
								회사는 회원이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의
								보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
							</li>
							<li>
								회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
								일반적인 온라인 서비스의 상식과 관례에 따릅니다.
							</li>
							<li>
								본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는
								회사는 제9조["회원"에 대한 통지]에 따라 통지합니다.
							</li>
							<li>
								회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라
								이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가
								인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
							</li>
						</ol>
						<h3> 제 18 조 (책임제한)</h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사의 고의
								또는 과실에 의한 경우가 아닌 경우에는 그 손해에 대하여 책임을
								지지 않습니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										천재지변 또는 이에 준하는 국가 비상사태 등으로 인하여
										서비스를 제공할 수 없는 경우
									</li>
									<li>
										정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한
										준수로 인하여 서비스 이용이 불가능하게 된 경우
									</li>
									<li>
										클라우드 시스템의 장애 또는 고객 측 귀책사유로 인하여 서비스
										장애가 발생한 경우
									</li>
									<li>
										순간적인 서비스 접속 증가 등으로 인한 서버의 장애가 발생한
										경우
									</li>
									<li>
										회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
										정확성 및 완전성 등의 내용이 변조, 허위 등의 사실이 확인된
										경우
									</li>
									<li>
										무료로 제공되는 서비스 이용과 관련하여 관련 법에 특별한
										규정이 없는 경우
									</li>
									<li>
										본 약관에 따른 이용제한 및 이용정지 조치로 인한 손해가
										발생하는 경우
									</li>
								</ol>
							</li>
							<li>
								회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나
								상실한 것에 대하여 책임을 지지 않습니다.
							</li>
							<li>
								회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에
								명시되지 않은 어떠한 사항에 대하여 직간접적인 약정이나 보증을
								하지 않습니다. 또한, 회사는 서비스를 통해 저장된
								보이스/보이스앨범 등 디지털 자산의 가치를 보증하지 않습니다.
							</li>
							<li>
								회사는 회원이 서비스를 이용하여 저장한 보이스/보이스앨범이
								제3자의 권리를 침해하는 경우 회사의 고의 또는 과실에 의한 경우가
								아닌 경우에는 그 손해에 대하여 책임을 지지 않습니다.
							</li>
						</ol>
						<h3>제 19 조 (손해배상) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사의 책임 있는 사유로 인하여 회원에게 손해가 발생한 경우
								회사의 손해배상 범위는 민법에서 정하고 있는 통상손해를 포함하고,
								특별한 사정으로 인한 손해는 회사가 그 사정을 알았거나 알 수
								있었을 때에 한하여 배상책임이 있습니다.
							</li>
							<li>
								제1항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는
								회원이 그 책임의 전부 또는 일부를 부담할 수 있습니다.
								<br />
								<br />
								<ol style={{ listStyleType: "decimal" }}>
									<li>
										회원이 손해 발생의 원인 또는 손해 발생 사실을 알았음에도
										불구하고 회사에 즉시 통지하지 않은 경우
									</li>
									<li>
										회원이 고의 또는 과실로 제3자에게 계정 정보를 유출하거나
										서비스를 임의로 사용하게 한 경우
									</li>
									<li>
										그 외에 손해 발생에 있어서 회원의 고의나 과실이 있는 경우
									</li>
								</ol>
							</li>

							<li>
								회사는 회사의 책임 없는 사유로 인하여 회원에게 발생한 손해에
								대하여는 배상책임이 없습니다.
							</li>
							<li>
								회원이 회사에 제공한 정보가 사실과 다를 경우, 회사는 언제든지
								서비스의 제공을 중단하고 본 약관에 의해 계약 전체 또는 일부를
								해지할 수 있으며, 이로 인하여 회사에 손해가 발생한 경우,
								회원에게 그 손해의 배상을 청구할 수 있습니다.
							</li>
							<li>
								회원이 회사의 서비스 또는 관련 시스템 운영을 방해하는 등 고의
								또는 과실로 법령, 본 약관, 사회질서에 위배하는 행위 등을 통하여
								회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두
								배상해야 합니다.
							</li>
						</ol>
						<h3>제 20 조 (준거법 및 재판관할) </h3>
						<ol style={{ listStyleType: "decimal" }}>
							<li>
								회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 합니다.
							</li>
							<li>
								회사와 회원 간 발생한 분쟁에 관한 소송은 제소 당시의 회원의
								주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
								전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가
								명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
							</li>
							<li>
								해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 발생한
								분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을
								관할법원으로 합니다.
							</li>
						</ol>
						<hr />
						<p>본 약관은 2022년 05월 16일부터 적용됩니다.</p>
					</Col>
				</Row>
			</div>
		</Container>
	);
}

export default TermsOfService;
