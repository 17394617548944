import { authService, dbService, storageService } from "fbase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Container, Row, Col } from "reactstrap";

const Profile = ({ userObj, refreshUser }) => {
	const [adafruitUsername, setAdafruitUsername] = useState("");
	const [adafruitActiveKey, setAdafruitActiveKey] = useState("");
	const [newDisplayName, setNewDisplayName] = useState("");

	useEffect(() => {
		if (userObj.displayName !== null) setNewDisplayName(userObj.displayName);
	}, []);

	const navigate = useNavigate();
	const onLogOutClick = () => {
		authService.signOut();
		navigate("/");
	};

	const onDeleteUserClick = async () => {
		const ok = window.confirm(
			`회원탈퇴를 하시면 저장된 데이터와 파일들이 모두 삭제됩니다. 회원탈퇴를 진행하시겠습니까?`
		);

		if (ok) {
			// 앨범 DB 삭제
			let isEmptyFolder = true;
			await dbService
				.collection("babies")
				.where("userID", "==", userObj.uid)
				.onSnapshot((snapshot) => {
					const newArray = snapshot.docs.map((document) => ({
						id: document.id,
						...document.data(),
					}));

					newArray.map(async (baby) => {
						// 아기정보 삭제
						await dbService.doc(`babies/${baby.id}`).delete();
					});
				});

			// console.log("인증정보 삭제");
			await authService.currentUser.delete();
			// console.log("로그아웃");
			await authService.signOut();
		}
	};

	const onChange = (event) => {
		setNewDisplayName(event.target.value);
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		if (userObj.displayName !== newDisplayName) {
			await userObj.updateProfile({ displayName: newDisplayName });
		}
		refreshUser();
	};

	console.log(userObj);

	return (
		<Container
			style={{
				maxWidth: 890,
				width: "100%",
				margin: "0 auto",
				marginTop: 80,
				display: "flex",
				justifyContent: "center",
			}}
		>
			<div className="container">
				<Row>
					<Col>
						<h3>사용자 닉네임 수정</h3>
					</Col>
					<Col></Col>
				</Row>
				<form onSubmit={onSubmit} className="profileForm">
					<Input
						bsSize="lg"
						onChange={onChange}
						value={newDisplayName}
						type="text"
						placeholder="별명"
						autoFocus
					/>
					<Button
						type="submit"
						value="닉네임 수정"
						style={{ marginTop: 10 }}
						color="success"
						size="lg"
					>
						닉네임 수정
					</Button>
				</form>
				<hr />
				<Row>
					<Col className="text-center">
						<Button
							onClick={onLogOutClick}
							className="mb-5"
							color="warning"
							size="lg"
						>
							로그아웃
						</Button>
					</Col>
				</Row>
				<Row>
					<Col className="text-center mb-5">
						<span
							className="cursor-pointer"
							onClick={() => onDeleteUserClick()}
						>
							회원탈퇴
						</span>
					</Col>
				</Row>
			</div>
		</Container>
	);
};

export default Profile;
