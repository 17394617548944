import React from "react";
import { Container, Row, Col, Table } from "reactstrap";

function Guide() {
	return (
		<section style={{ backgroundColor: "#666" }}>
			<Container className="mt-5 mb-5">
				<Row>
					<Col className="text-center">
						<h2 className="text-light">주요 대화문구</h2>
						<p className="mt-3 text-light">
							아기를 안고서 원하시는 정보를 말로 기록하고, 확인해 보세요.
							<br />
							대화를 시작하실 때는 먼저,{" "}
							<span className="fw-bold">"클로바! 육아일기에서"</span>를 먼저
							말해주세요.
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table
							dark
							bordered
							hover
							responsive
							size="lg"
							className="text-light mt-3 text-center"
						>
							<thead>
								<tr className="fw-bold">
									<th className="p-3">구분</th>
									<th>수유</th>
									<th>배변</th>
									<th>수면</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" className="text-center fw-bold p-3">
										기록할 때
									</th>
									<td>분유 120미리 기록해줘. </td>
									<td>응가 기록해줘.</td>
									<td>수면 2시간 기록해줘.</td>
								</tr>
								<tr>
									<th scope="row" className="text-center fw-bold p-3">
										확인할 때
									</th>
									<td>수유정보 확인해줘</td>
									<td>배변정보 확인해줘</td>
									<td>수면정보 확인해줘</td>
								</tr>
							</tbody>
						</Table>
						<p className="text-light">
							* 쌍둥이 일 때는 첫째, 둘째로 구분해서 말해주세요. 예) 클로바!
							육아일기에서 둘째아기 분유100미리 기록해줘!
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default Guide;
