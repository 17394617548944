import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

function DocsLink() {
	return (
		<Row>
			<Col className="mt-5 col-12 text-center border-bottom ">
				<div className="p-2">
					<Link to="/privacy-policy" className="font-bold">
						개인정보 처리방침
					</Link>
					{" |"}
					<Link to="/terms-of-service" className="p-2 font-bold">
						서비스 이용약관
					</Link>
					{" |"}
					<Link to="/operation-policy" className="p-2 font-bold">
						운영정책
					</Link>
				</div>
			</Col>
		</Row>
	);
}

export default DocsLink;
