import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Keyboard, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import imgAdMomssi from "images/About/imgAdMomssi.gif";
import imgStructure from "images/About/imgStructure.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Samples() {
	return (
		<section style={{ background: "white" }}>
			<Container className="mt-5 mb-5">
				<Row>
					<Col className="text-center">
						<h2>육아비서가 하는 일</h2>
						<p className="mt-3 text-secondary">
							사랑하는 아기가 하고 있는 놀라운 일들을 기록하고 확인 하세요.
							<br />
							오늘도 잘 먹고, 잘 싸고, 잘 자는 건강한 일상이 되길 바래요.
						</p>
					</Col>
				</Row>
				<Swiper
					slidesPerView={1}
					spaceBetween={30}
					keyboard={{
						enabled: true,
					}}
					pagination={{
						clickable: true,
					}}
					navigation={true}
					modules={[Keyboard, Pagination, Navigation]}
				>
					<SwiperSlide index={1}>
						<Row className="container-890">
							<Col className="text-center valign-middle mb-3">
								<img
									src={imgStructure}
									alt="맘씨 서비스 구성"
									className="shadow-sm rounded w-100"
								/>
							</Col>
						</Row>
					</SwiperSlide>
					<SwiperSlide index={2}>
						<Row className="container-890">
							<Col className="text-left valign-middle mb-3" md={"7"}>
								<iframe
									className="video-width"
									src="https://www.youtube.com/embed/LN2c1zfMR5A"
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</Col>
							<Col className="text-center valign-middle">
								<p className="h5 p-2">
									아기가 울 때면 배고플 때가 되었나 궁금하시죠?
									<br />
									맘씨에서 수유정보를 확인해 보세요. <br />
									오늘 <u>얼마나 먹었는지 그리고 마지막 수유시간과 수유량</u>
									까지 알려줍니다. <br />
									<br />
									“클로바! 육아일기에서 수유정보 확인해줘~~~”
								</p>
							</Col>
						</Row>
					</SwiperSlide>
					<SwiperSlide index={4}>
						<Row className="container-890">
							<Col className="text-left valign-middle mb-3" md={"7"}>
								<iframe
									className="video-width"
									src="https://www.youtube.com/embed/FPdabf2CkvI"
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</Col>
							<Col className="text-center valign-middle">
								<p className="h5 p-2">
									아기가 분유를 다 먹었네요.
									<br />
									에구 기특해라… <br />
									맘씨에 수유 정보를 기록해 보세요.
									<br /> <br />
									“클로바! 육아일기에서 분유 200미리 기록해줘” <br />
								</p>
							</Col>
						</Row>
					</SwiperSlide>
					<SwiperSlide index={3}>
						<Row className="container-890">
							<Col className="text-center valign-middle mb-3" md={"7"}>
								<img
									src={imgAdMomssi}
									alt="맘씨 홍보 이미지"
									style={{ width: "90%" }}
									className="shadow-sm rounded"
								/>
							</Col>
							<Col className="text-start valign-middle">
								<p className="h5 p-2">
									우리 아가 오늘 얼마나 먹었지?
									<br />
									오늘 응가는 몇 번 했더라?
									<br />
									낮잠은 얼마나 잔거지?
									<br />
									<br />
									홈페이지에서 그 동안의 기록을 확인하세요.
									<br />
									모바일, PC 어디든 사용하기 좋아요.
									<br />
								</p>
							</Col>
						</Row>
					</SwiperSlide>
				</Swiper>
			</Container>
		</section>
	);
}

export default Samples;
