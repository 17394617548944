import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<section className="border-top">
			<div>
				<Container className="mb-5 mt-5">
					<Row>
						<Col md={6}>
							<div className="p-2">
								<Link to="/privacy-policy" className="font-bold">
									개인정보 처리방침
								</Link>
								{" |"}
								<Link to="/terms-of-service" className="p-2 font-bold">
									서비스 이용약관
								</Link>
								{" |"}
								<Link to="/operation-policy" className="p-2 font-bold">
									운영정책
								</Link>
								<br />
								<p className="mt-3">
									서울시 성동구 뚝섬로 13길 38 (성수동 2가) 상상플래닛 4층
								</p>
							</div>
						</Col>
						<Col md={6}>
							<div className="p-2">
								사업자등록번호 : 623-88-00397
								<p className="mt-3">
									통신판매업신고번호 : 제2019-서울성동-808호
								</p>
								Copyright {new Date().getFullYear()} 따보(주) Inc. All Rights
								Reserved.{" "}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
}

export default Footer;
