import { authService, firebaseInstance } from "fbase";
import { useMemo } from "react";
import AuthForm4Clova from "components/AuthForm4Clova";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGoogle,
	faGithub,
} from "@fortawesome/free-brands-svg-icons";

const Auth4Clova = () => {
	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	let query = useQuery();

	const onSocialClick = async (event) => {
		const {
			target: { name },
		} = event;
		let provider;
		if (name === "google") {
			provider = new firebaseInstance.auth.GoogleAuthProvider();
		}

		const data = await authService.signInWithPopup(provider);
		console.log(data);
	};

	return (
		<>
			<div className="authContainer">
				{/* <FontAwesomeIcon
					icon={faTwitter}
					color={"#04AAFF"}
					size="3x"
					style={{ marginBottom: 30 }}
				/> */}
				<AuthForm4Clova
					state={query.get("state")}
					client_id={query.get("client_id")}
					scope={query.get("scope")}
					response_type={query.get("response_type")}
					redirect_uri={query.get("redirect_uri")}
				/>

				<button onClick={onSocialClick} name="google" className="authBtn">
					구글 로그인 <FontAwesomeIcon icon={faGoogle} />
				</button>
			</div>
		</>
	);
};

export default Auth4Clova;
