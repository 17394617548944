import { authService, analyticsService } from "fbase";
import { useState, useEffect } from "react";
import { Form, Input, Label, FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";

const AuthForm = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [newAccount, setNewAccount] = useState(true);
	const [error, setError] = useState("");
	const [agree, setAgree] = useState({
		terms: false,
		privacy: false,
	});

	useEffect(() => {
		analyticsService.logEvent("auth_page_visited");
	}, []);

	const onChange = (event) => {
		const {
			target: { name, value },
		} = event;
		if (name === "email") {
			setEmail(value);
		} else if (name === "password") {
			setPassword(value);
		}
	};

	const msgTable = {
		"auth/missing-email": "이메일을 입력하세요.",
		"auth/invalid-email": "이메일 형식에 맞게 입력하세요.",
		"auth/user-not-found": "등록된 사용자가 아닙니다. 회원가입을 해주세요.",
		"auth/wrong-password":
			"패드워드가 틀립니다. 패드워드를 잊으신 경우 아래 '비밀번호 재설정'을 클릭 해주세요.",
		"auth/weak-password":
			"영문/숫자를 혼합해서 6자 이상의 비밀번호를 입력해주세요.",
		"auth/email-already-in-use":
			"이미 가입된 이메일입니다. 로그인을 원하실 경우 아래 로그인 버튼을 클릭해주세요.",
	};

	const convertErrorMessage = (msg) => {
		let res = msg;

		try {
			res = msgTable[msg];
		} catch {}

		return res;
	};

	const resetPassword = () => {
		authService
			.sendPasswordResetEmail(email)
			.then(() => {
				alert(
					"패스워드 리셋 메일이 발송되었습니다. 메일함을 확인해 주세요. 메일발송은 약5분 정도 소요됩니다."
				);
			})
			.catch((error) => {
				console.log("에러코드", error.code);
				alert(convertErrorMessage(error.code));
			});
	};

	const onChangeAgree = (kind) => {
		let prevValue = { ...agree };
		if (kind === "terms") {
			prevValue.terms = !agree.terms;
		} else {
			prevValue.privacy = !agree.privacy;
		}
		setAgree(prevValue);
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		try {
			let data;
			if (newAccount) {
				data = await authService.createUserWithEmailAndPassword(
					email,
					password
				);
			} else {
				data = await authService.signInWithEmailAndPassword(email, password);
			}
			navigate("/albums");
		} catch (error) {
			setError(convertErrorMessage(error.code));
		}
	};

	const toggleAccount = () => setNewAccount((prev) => !prev);

	return (
		<>
			<h2 className="mb-3">{newAccount ? "회원가입" : "로그인"}</h2>
			<Form onSubmit={onSubmit} className="loginForm">
				<Input
					name="email"
					value={email}
					onChange={onChange}
					type="email"
					placeholder="Email"
					required
					className="authInput"
				/>
				<Input
					name="password"
					value={password}
					onChange={onChange}
					type="password"
					placeholder="Password"
					required
					className="authInput"
				/>
				{newAccount && (
					<>
						<div className="p-2">
							<FormGroup check>
								<Label check>
									<Input
										type="checkbox"
										id="terms"
										value={agree.terms}
										checked={agree.terms}
										onChange={() => onChangeAgree("terms")}
									/>{" "}
									<a href="/terms-of-service" target="_blank" rel="noreferrer">
										이용약관
									</a>
								</Label>
							</FormGroup>
							<FormGroup check>
								<Label check>
									<Input
										type="checkbox"
										id="privacy"
										checked={agree.privacy}
										value={agree.privacy}
										onChange={() => onChangeAgree("privacy")}
									/>{" "}
									<a href="/privacy-policy" target="_blank" rel="noreferrer">
										개인정보 처리방침
									</a>
								</Label>
							</FormGroup>
						</div>
					</>
				)}
				<Input
					className="authInput authSubmit"
					type="submit"
					value={newAccount ? "회원가입" : "로그인"}
					disabled={
						!newAccount
							? false
							: (agree.privacy !== false) & (agree.terms !== false)
							? false
							: true
					}
				/>
				{error && <span className="authError">{error}</span>}
			</Form>
			<span onClick={toggleAccount} className="authSwitch">
				{newAccount ? "로그인" : "회원가입"}
			</span>
			<span className="authSwitch" onClick={() => resetPassword()}>
				{!newAccount && "비밀번호 재설정"}
			</span>
		</>
	);
};

export default AuthForm;
