import { useState } from "react";
import { Link } from "react-router-dom";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavLink,
	Container,
} from "reactstrap";

import logo from "images/logo.png";

const Navigation = ({ userObj }) => {
	const [collapsed, setCollapsed] = useState(false);
	const toggleNavbar = () => {
		setCollapsed(!collapsed);
	};

	return (
		<div
			className="bg-light"
			style={{
				maxWidth: "960",
				width: "100%",
				margin: "0 auto",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<Container className="mt-3">
				<Navbar light expand="md">
					<Link to="/">
						{/* <img src={logo} style={{ width: "140px" }} alt="보이스 뿜뿜 로고" /> */}
						<span className="nav-links-mobile h5 fw-bold">
							DDABO-STEAM-사물인터넷
						</span>
					</Link>
					<NavbarToggler onClick={toggleNavbar} />
					<Collapse isOpen={collapsed} className="justify-content-end" navbar>
						{userObj ? (
							<Nav className="ml-auto" navbar>
								<Link
									className="nav-links-mobile h5 fw-bold"
									to="/iot"
									onClick={() => setCollapsed(false)}
									style={{ color: "black", marginRight: 20 }}
								>
									IoT실습설정
								</Link>
								<Link
									to="/profile"
									onClick={() => setCollapsed(false)}
									className="nav-links-mobile h5 fw-bold"
								>
									마이페이지
								</Link>
							</Nav>
						) : (
							<Nav className="ml-auto" navbar>
								<NavLink
									href="/login"
									className="nav-links-mobile h5 fw-bold"
									style={{ color: "black" }}
								>
									로그인
								</NavLink>{" "}
								<NavLink
									href="/signup"
									className="nav-links-mobile h5 fw-bold"
									style={{ color: "black" }}
								>
									회원가입
								</NavLink>
							</Nav>
						)}
					</Collapse>
				</Navbar>
			</Container>
		</div>
	);
};

export default Navigation;
