import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Button,
	Spinner,
	FormGroup,
	Input,
	Label,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import "moment/locale/ko";
import { dbService, storageService } from "fbase";
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUserCog } from "@fortawesome/free-solid-svg-icons";

function Iot({ userObj }) {
	const [docID, setDocID] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isShowAuthForm, setIsShowAuthForm] = useState(false);
	const [isShowFeedsForm, setIsShowFeedsForm] = useState(false);

	const [iot, setIoT] = useState({
		auth: {
			userID: "",
			userKey: "",
		},
		feeds: {
			led: {
				feedName: "",
			},
			hum: {
				feedName: "",
			},
			temp: {
				feedName: "",
			},
		},
		userID: userObj.uid,
	});

	useEffect(() => {
		// 저장된 정보 가져오기
		getData();
	}, []);

	// 시간표현 변환
	const convertTimeFormat = (ts) => {
		return moment(ts.seconds * 1000).format("YYYY-MM-DD HH:mm");
	};

	// Adafruit Auth셋팅
	const onSubmitAdafruitAuth = async () => {
		if (docID) {
			await dbService.doc(`iot/${docID}`).update({
				auth: {
					userID: iot.auth.userID,
					userKey: iot.auth.userKey,
				},
			});
		} else {
			if (iot.auth.userID === "" || iot.auth.userKey === "") return;

			await dbService.collection("iot").add(iot);
		}

		setIsShowAuthForm(false);
	};

	// Adafruit Auth셋팅
	const onSubmitAdafruitFeeds = async () => {
		if (docID) {
			await dbService.doc(`iot/${docID}`).update({
				feeds: {
					led: {
						feedName: iot.feeds.led.feedName,
					},
					temp: {
						feedName: iot.feeds.temp.feedName,
					},
					hum: {
						feedName: iot.feeds.hum.feedName,
					},
				},
			});
		} else {
			alert("Adafruit userID와 userKey값을 먼저 입력하고 저장해 주세요.");
		}
		setIsShowFeedsForm(false);
	};

	const getData = async () => {
		setIsLoading(true);

		await dbService
			.collection("iot")
			.where("userID", "==", userObj.uid)
			.onSnapshot((snapshot) => {
				let data = [];

				if (snapshot.docs.length === 0) {
					console.log("nothing");

					setIsShowAuthForm(true);
					setIsShowFeedsForm(true);
					return;
				} else {
					data = snapshot.docs.map((document) => ({
						id: document.id,
						...document.data(),
					}));

					setDocID(data[0].id);

					let rawData = { ...iot };
					rawData.auth = data[0].auth;
					rawData.feeds.led.feedName = data[0].feeds.led.feedName;
					rawData.feeds.temp.feedName = data[0].feeds.temp.feedName;
					rawData.feeds.hum.feedName = data[0].feeds.hum.feedName;
					setIoT(rawData);
				}
			});

		setIsLoading(false);
	};

	const onChangeAuth = (e) => {
		let name = e.target.name;
		let rawData = { ...iot };

		rawData.auth[name] = e.target.value;
		setIoT(rawData);
	};

	const onChangeFeeds = (e) => {
		let name = e.target.name;
		let rawData = { ...iot };

		rawData.feeds[name].feedName = e.target.value;
		setIoT(rawData);
	};

	const checkFeed = async (kind) => {
		let feedName = "";

		if (kind === "led") {
			if (iot.feeds.led.feedName === "") {
				alert("Adafruit에서 LED를 제어할 피드명을 입력하세요.");
				return;
			}
			feedName = iot.feeds.led.feedName;
		} else if (kind === "temp") {
			if (iot.feeds.led.feedName === "") {
				alert("Adafruit에서 온도값을 가져올 피드명을 입력하세요.");
				return;
			}
			feedName = iot.feeds.temp.feedName;
		} else {
			if (iot.feeds.led.feedName === "") {
				alert("Adafruit에서 습도값을 가져올 피드명을 입력하세요.");
				return;
			}
			feedName = iot.feeds.hum.feedName;
		}

		let path = `https://io.adafruit.com/api/v2/${iot.auth.userID}/feeds/${feedName}/data?limit=1`;
		let res = null;
		try {
			res = await axios
				.get(path, {
					headers: {
						"X-AIO-Key": `${iot.auth.userKey}`,
					},
				})
				.catch((e) => {
					let err = JSON.stringify(e);
					console.log("e is", err);
				});

			let msg = `최근 값은 '${res.data[0].value}' @(${moment(
				res.data[0].created_at
			).format("YYYY-MM-DD HH:mm")})`;
			alert(msg);
		} catch (e) {
			res = e;
			alert("Adafruit 설정 정보를 확인해주세요.");
		}
	};

	return (
		<Container className="content">
			{isLoading ? (
				<div style={{ width: "100%", height: "100%" }}>
					<Spinner color="info" size="" type="grow">
						Loading...
					</Spinner>
				</div>
			) : (
				<>
					<Row>
						<Col className="text-center p-5">IoT 실습 구조도</Col>
					</Row>

					<Row style={{ marginTop: 10 }}>
						<Col md={6}>
							<p className="h2 font-weight-bold">Adafruit IO 연결정보</p>
							{isShowAuthForm ? (
								<div>
									<FormGroup>
										<Label for="userID">Adafruit IO UserID</Label>
										<Input
											bsSize="lg"
											onChange={onChangeAuth}
											name="userID"
											value={iot.auth.userID}
											type="text"
											placeholder="Adafruit IO UserID"
											autoFocus
											className="mb-2"
										/>
									</FormGroup>
									<FormGroup>
										<Label for="userKey">Adafruit IO UserKey</Label>
										<Input
											bsSize="lg"
											onChange={onChangeAuth}
											name="userKey"
											value={iot.auth.userKey}
											type="text"
											placeholder="Adafruit IO UserKey"
											autoFocus
										/>
									</FormGroup>
									<Button
										onClick={() => onSubmitAdafruitAuth()}
										value="iot auth 정보 저장"
										style={{ marginTop: 10 }}
										color="success"
										size="lg"
									>
										Adafruit IO 접속정보 저장
									</Button>{" "}
									<Button
										onClick={() => setIsShowAuthForm(false)}
										style={{ marginTop: 10 }}
										color="secondary"
										size="lg"
									>
										취소
									</Button>
								</div>
							) : (
								<div>
									<li>Adafruit IO userID : {iot.auth.userID}</li>
									<li>Adafruit IO userKey : {iot.auth.userKey}</li>
									<Button
										style={{ marginTop: 10 }}
										color="success"
										size="lg"
										onClick={() => setIsShowAuthForm(true)}
									>
										Adafruit 접속정보 재설정
									</Button>
								</div>
							)}
						</Col>
						<Col md={6}>
							<p className="h2 font-weight-bold">피드명 설정정보</p>
							{isShowFeedsForm ? (
								<div>
									<FormGroup>
										<Label for="led">LED 피드명</Label>
										<Input
											bsSize="lg"
											onChange={onChangeFeeds}
											name="led"
											value={iot.feeds.led.feedName}
											type="text"
											placeholder="LED를 제어를 위한 Adafruit Feed명"
											autoFocus
											className="mb-2"
										/>
									</FormGroup>
									<FormGroup>
										<Label for="temp">온도 피드명</Label>
										<Input
											bsSize="lg"
											onChange={onChangeFeeds}
											name="temp"
											value={iot.feeds.temp.feedName}
											type="text"
											placeholder="온도 데이터 확인을 위한 Adafruit Feed명"
											autoFocus
											className="mb-2"
										/>
									</FormGroup>
									<FormGroup>
										<Label for="hum">습도 피드명</Label>
										<Input
											bsSize="lg"
											onChange={onChangeFeeds}
											name="hum"
											value={iot.feeds.hum.feedName}
											type="text"
											placeholder="습도 데이터 확인을 위한 Adafruit Feed명"
											autoFocus
											className="mb-2"
										/>
									</FormGroup>
									<Button
										onClick={() => onSubmitAdafruitFeeds()}
										style={{ marginTop: 10 }}
										color="success"
										size="lg"
									>
										Adafruit IO Feed정보 저장
									</Button>{" "}
									<Button
										onClick={() => setIsShowFeedsForm(false)}
										style={{ marginTop: 10 }}
										color="secondary"
										size="lg"
									>
										취소
									</Button>
								</div>
							) : (
								<div>
									<li>
										LED 피드명 : {iot.feeds.led.feedName}{" "}
										<span onClick={() => checkFeed("led")}>[상태 확인]</span>
									</li>
									<li>
										온도 피드명 : {iot.feeds.temp.feedName}{" "}
										<span onClick={() => checkFeed("temp")}>[상태 확인]</span>
									</li>
									<li>
										습도 피드명 : {iot.feeds.hum.feedName}{" "}
										<span onClick={() => checkFeed("hum")}>[상태 확인]</span>
									</li>

									<Button
										style={{ marginTop: 10 }}
										color="success"
										size="lg"
										onClick={() => setIsShowFeedsForm(true)}
									>
										Adafruit IO Feeds 정보 재설정
									</Button>
								</div>
							)}
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
}

export default Iot;
